import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Routes from './routes';

import i18n from './translate';

import { getCurrentLanguageAsync } from './services/application-service';
import { withLoadingAsync, showToast } from './services/common-service';
import BlipPortalToastTypes from './constants/blip-portal-toast-types';
import Providers from './contexts/Providers';
import { getApplications, getUsersFromTenant } from './services/blip';

const DEFAULT_LANGUAGE = 'pt';

function getQueryString(a) {
    a = a || window.location.search.substr(1).split('&').concat(window.location.hash.substr(1).split("&"));

    if (typeof a === "string")
        a = a.split("#").join("&").split("&");

    // se não há valores, retorna um objeto vazio
    if (!a) return {};

    var b = {};
    for (var i = 0; i < a.length; ++i) {
        // obtem array com chave/valor
        var p = a[i].split('=');

        // se não houver valor, ignora o parametro
        if (p.length != 2) continue;

        // adiciona a propriedade chave ao objeto de retorno
        // com o valor decodificado, substituindo `+` por ` `
        // para aceitar URLs codificadas com `+` ao invés de `%20`
        b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
    }
    // retorna o objeto criado
    return b;
}

const App = () => {
    const { t } = useTranslation();

    useEffect(() => {
        getInitialInfoAsync();
        // eslint-disable-next-line
        test()
    }, []);

    const getInitialInfoAsync = async () => {
        await withLoadingAsync(async () => {
            await getLanguageAsync();

            showToast({
                type: BlipPortalToastTypes.SUCCESS,
                message: t('success.loaded')
            });
        });
    };

    const getLanguageAsync = async () => {
        const language = await getCurrentLanguageAsync();

        if (!!language && language !== DEFAULT_LANGUAGE) {
            i18n.changeLanguage(language);
        }
    };

    const test = async () => {
        // try {
        //     let result = await getApplications("blackpool-shopping-1t1w9")
        //     console.log("TESTTTTT", result)
        // } catch (error) {
        //     console.error("ERROOORRRR", error)
        // }
    }

    return (
        <div className="flex" style={{ height: "100vh", justifyContent: "center", }}>
            <iframe
                style={{ flex: 1 }}
                src={getQueryString().url}
            />
        </div>
    )
};

export default App;
